@import 'pages/variables.scss';

.otpInputContainer {
  width: 100%;
  gap: 24px;

  div {
    flex: 25%;

    .otpInput {
      width: 100% !important;
      min-height: 40px;
      border: 1px solid $gray-200;
      border-radius: 6px;
      background: $white;
    }

    .otpInputFocus {
      outline-color: $teal-500;
    }
  }
}
