.inputContainer{
  [class*='chakra-form__error-message']{
    position: absolute;
  }
}
  
.confirmCheckbox{
    [class*='chakra-checkbox__control']{
      position: absolute;
      top: 2px;
    }
    [class*='chakra-checkbox__label']{
      margin-left: 1.5rem;
    }
}